@import "./reset.css";

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}

.layout {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

main {
  flex: 1 0 auto;
}

footer {
  flex: 0 0 auto;
  padding: 20px 30px;
}


/* костыль скрытия кнопки в маркдауне */
.button.button-type-image,
.button.button-type-table,
.button.button-type-ordered,
.button.button-type-quote,
.button.button-type-wrap,
.button.button-type-link,
.button-type-underline,
.button.button-type-header {
  display: none !important;
}

/* Чтобы текст постов отображался корректно (из телеги возвращает эти тэги) */
b, strong {
  font-weight: bold;
}

i, em {
  font-style: italic;
}

s, strike, del {
  text-decoration: line-through;
}

u {
  text-decoration: underline;
}